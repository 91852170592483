.controlContainer {
  width: 290px;
  background: #ffffff;
  color: #495057;
  border-radius: 3px;
  text-overflow: ellipsis;
  position: absolute;
  right: 0;
  z-index: 1001;
  margin-top: 20px;
  margin-right: 20px;
  padding: 3px 6px;
  font-size: 12px;
  display: flex;
  flex-direction: column;

  .w-100 {
    width: 100%;
  }

  .singleCheckbox {
    margin: 0;
    font-size: 12px;

    label {
      margin-left: 5px;
    }

  }

  .actionBar {
    width: 289px;
    background-color: white;
    border-top: 1px solid #dedede;
    margin-left: -5px;
  }

}

@media (max-width: 600px) {
  .controlContainer {
    width: 100%;
    margin: 0px;
    top: 0;
    right: 0;
    left: 0;
    .actionBar {
      width: 100%;
      margin: 0px;
    }
  }
}