.placePanel {
  .p-card {

    .p-card-header {
      display: inline-flex;
      width: 100%;
      padding: 1rem 1rem 0;
      justify-content: flex-end;

      .placePanelHeader {
        display: inline-flex;
        width: 100%;
      }

      .closeButton {
        cursor: pointer;
      }

      span {
        font-size: 20px;
      }

    }

    .p-card-title {
      font-size: 18px;
    }

    .p-card-body {
      margin-bottom: 0;
      padding-bottom: 0;
      .p-card-content {
        p {
          font-size: 13px;
        }
      }

      .p-card-footer {
        font-size: 13px;
        .actionBar {
          margin-top: 10px;
          border-top: 1px solid #dedede;

          button {
            font-size: 10px;
            .buttonIcon {
              margin-left: 0.5rem;
            }
          }
        }
      }

    }
  }
}