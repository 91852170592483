.sectionImage {
  background: url("gfx/loginPageImage.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
}

.loginFormContainer {
  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 6px;
  }

  .subContainer {
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #000000;
    font-weight: normal;

    a {
      color: #1A8B9D;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .formContainer {
    margin-top: 30px;

    .formRow {
      margin: 20px 0;
      .p-float-label {
        input {
          width: 370px;
          height: 40px;
          border: 1px solid #B3B3B3;
          box-sizing: border-box;
          border-radius: 5px;
        }

        label {
          font-size: 13px;
          line-height: 22px;
          color: #000000;
          font-weight: 400;
          margin-top: -10px;
        }
      }

      .p-field-checkbox {
        label {
          margin-left: 10px;
        }
      }

      .p-float-label, .p-field-checkbox {
        input {
          width: 370px;
          height: 40px;
          border: 1px solid #B3B3B3;
          box-sizing: border-box;
          border-radius: 5px;
        }

        label {
          font-size: 13px;
          line-height: 22px;
          color: #000000;
          font-weight: 400;
          margin-top: -10px;
        }
      }

    }

    .forgotPasswordRow {
      a {
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #000000;
        text-decoration: none;
        &:hover {
          color: #1A8B9D;
          text-decoration: underline;
        }
      }
    }

    .submitButton {
      margin-top: 25px;
      button {
        display: block;
        width: 100%;
        background: #2C5D63;
        border-radius: 5px;
        border: none;
        font-weight: 500;
        &:hover {
          background: #2a5459;
        }
      }
    }

  }

}